import { Injectable } from '@angular/core';
import { CreateWriteStreamOptions } from 'streamsaver';

import { ExportStreamEncoder } from '../encoders/export-stream.encoder';
import { ExportStream } from '../streams/export.stream';

import { WriteStreamService } from './write-stream.service';

export interface ExportStreamOptions<T> {
  filename: string;
  encoder: ExportStreamEncoder<T>;
  options?: CreateWriteStreamOptions;
}

@Injectable()
export class ExportStreamService extends WriteStreamService {
  public createStream<T>(options: ExportStreamOptions<T>): ExportStream<T> {
    return new ExportStream<T>(
      this.createWritableStream(options.filename, options.options),
      options.encoder
    );
  }
}
