import { WritableStream } from 'streamsaver';

import { WriteStreamEncoder } from '../encoders/write-stream.encoder';

export abstract class WriteStream<W, R> {
  protected readonly _writer: WritableStreamDefaultWriter<R>;

  protected closed = false;

  protected constructor(
    protected readonly _stream: WritableStream,
    protected readonly _encoder: WriteStreamEncoder<W, R>
  ) {
    this._writer = this._stream.getWriter();
  }

  public onClose: () => void = () => {};
  public onAbort: () => void = () => {};
  public onWrite: () => void = () => {};

  public async write(chunk: W): Promise<void> {
    return this._writer
      .write(await this._encoder.encode(chunk))
      .then(this.onWrite);
  }

  public abort(reason?: string): Promise<void> {
    if (this._writer) {
      return this._writer.abort(reason).then(this.onAbort);
    }
    return this._stream.abort(reason).then(this.onAbort);
  }

  public close(): Promise<void> {
    return this._writer.close().then(() => {
      this.closed = true;
      this.onClose();
    });
  }
}
