import { WriteStreamEncoder } from './write-stream.encoder';

export class StringStreamEncoder
  implements WriteStreamEncoder<string, Uint8Array>
{
  public static encodeArray(
    array: string[],
    delimiter = ',',
    newLine = true
  ): Uint8Array {
    const withoutDelimiter = array.map(
      (a) => String(a)?.replace(new RegExp(delimiter, 'g'), '') ?? ''
    );
    return new StringStreamEncoder().encode(
      `${withoutDelimiter.join(delimiter)}${newLine ? '\n' : ''}`
    );
  }

  public encode(value: string): Uint8Array {
    return new TextEncoder().encode(value);
  }
}
