import {
  createWriteStream,
  CreateWriteStreamOptions,
  WritableStream,
} from 'streamsaver';

export abstract class WriteStreamService {
  protected createWritableStream(
    filename: string,
    options?: CreateWriteStreamOptions
  ): WritableStream {
    return createWriteStream(filename, options);
  }
}
